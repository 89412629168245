import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/addtimeslot.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Addtimeslot = () => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [formData, setFormData] = useState({
    MT_SLOT_DATE: '',
    MT_TIMESLOT: '',
    MT_START_TIME: '',
    MT_PATIENT_NO: 0,
    MT_END_TIME: '',
    MT_MAXIMUM_PATIENTS: '',
    MT_DOCTOR: '',
    MT_ALLOCATED_TIME: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [timeslotData, setTimeslotData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSuggestionClick = (doctorName) => {
    setSelectedDoctor(doctorName);
    setQuery(doctorName);
    setFormData((prevFormData) => ({
      ...prevFormData,
      MT_DOCTOR: doctorName
    }));
    setSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adjustedFormData = {
      ...formData,
      MT_TIMESLOT: formData.MT_TIMESLOT + ":00",
      MT_START_TIME: formData.MT_START_TIME + ":00",
      MT_END_TIME: formData.MT_END_TIME + ":00",
      MT_ALLOCATED_TIME: formData.MT_START_TIME + ":00",
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/Timeslot`, adjustedFormData);
      alert('Timeslot added successfully!');
      // Fetch timeslots again without reloading the page
      fetchTimeslots();
    } catch (error) {
      console.error("Failed to add timeslot", error.response?.data || error.message);
      setErrorMessage('Failed to add timeslot.');
    }
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;
    setQuery(searchValue);

    if (searchValue.length > 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/User/suggest?query=${searchValue}`
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const fetchTimeslots = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Timeslot`);
      setTimeslotData(response.data);
    } catch (error) {
      console.error('Error fetching timeslots:', error);
      setErrorMessage('Failed to load timeslots. Please try again later.');
    }
  };

  const Deletetime = async (id) => {
    if (window.confirm("Are you sure you want to delete this timeslot?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/Timeslot/${id}`);
        alert("Timeslot deleted successfully");
        // Update timeslot data after deletion
        setTimeslotData(timeslotData.filter((timeslot) => timeslot.MT_SLOT_ID !== id));
      } catch (error) {
        console.error("Error deleting timeslot:", error.response?.data || error.message);
        alert("Failed to delete timeslot.");
      }
    }
  };

  useEffect(() => {
    fetchTimeslots();
    const interval = setInterval(() => {
      fetchTimeslots();
    }, 60000); // Fetch every 60 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div>
      <div className="addtimeslot-container">
        <h1>Add Available Timeslot</h1>
        <form className="form-timeslot" onSubmit={handleSubmit}>
          <div className="form-group-timeslot">
            <label>Date</label>
            <input
              type="date"
              name="MT_SLOT_DATE"
              value={formData.MT_SLOT_DATE}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group-timeslot">
            <label>Start Time</label>
            <input
              type="time"
              name="MT_START_TIME"
              value={formData.MT_START_TIME}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group-timeslot">
            <label>End Time</label>
            <input
              type="time"
              name="MT_END_TIME"
              value={formData.MT_END_TIME}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group-timeslot">
            <label>Maximum Patients</label>
            <input
              type="number"
              name="MT_MAXIMUM_PATIENTS"
              value={formData.MT_MAXIMUM_PATIENTS}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group-timeslot">
            <label>Doctor Name</label>
            <input
              type="search"
              placeholder="Enter doctor name"
              value={query}
              onChange={handleSearch}
            />

            {suggestions.length > 0 && (
              <ul className="doctor-suggestions">
                {suggestions.map((doctorName, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(doctorName)}>
                    {doctorName}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <button className="submit-button" type="submit">Add Timeslot</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>

        <div className="view-timeslot">
          {timeslotData.length > 0 ? (
            <div className="timeslot-container">
              {timeslotData.map((timeslot) => (
                <div key={timeslot.MT_SLOT_ID} className="timeslot-card">
                  <button className="delete-btn2" onClick={() => Deletetime(timeslot.MT_SLOT_ID)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <div className="timeslot-time">
                    <p><strong>Date:</strong> {new Date(timeslot.MT_SLOT_DATE).toLocaleDateString()}</p>
                    <p><strong>Time:</strong> 
                      {new Date(`1970-01-01T${timeslot.MT_START_TIME}`).toLocaleTimeString('en-LK', {
                        timeZone: 'Asia/Colombo',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}
                      -
                      {new Date(`1970-01-01T${timeslot.MT_END_TIME}`).toLocaleTimeString('en-LK', {
                        timeZone: 'Asia/Colombo',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}
                    </p>
                    <p><strong>Doctor Name:</strong> {timeslot.MT_DOCTOR}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-appointments">No available timeslots</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addtimeslot;
