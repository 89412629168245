import React, { useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import '../styles/patientlogin.css';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function Patientlogin() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const navigate = useNavigate();

  const generateOtp = () => Math.floor(100000 + Math.random() * 900000);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const checkUserExists = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/AppoinmentLogin/CheckUserExists`,
        { email }
      );
      return response.status === 200; // Return true if user exists
    } catch (error) {
      setErrorMessage("User not registered. Please sign up first.");
      return false;
    }
  };

  const sendOtp = async () => {
    if (!email || !validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    // Pass `email` as an argument
    const userExists = await checkUserExists(email);
    if (!userExists) return;

    // Proceed to generate and send OTP
    const otp = generateOtp();
    setGeneratedOtp(otp);

    emailjs.send(
      "service_naq3m94",
      "template_m51z368",
      {
        to_email: email,
        otp: otp,
      },
      "5Z0ZQCQbi6vWIGWpC"
    )
      .then(() => {
        setErrorMessage(""); // Clear any previous error
        alert("OTP sent to your email");
        console.log(otp);
        setIsOtpSent(true);
      })
      .catch(error => {
        console.error("Error sending OTP:", error);
        setErrorMessage("Failed to send OTP. Please try again.");
      });
  };

  const handleLogin = async () => {
    if (otp === generatedOtp?.toString()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/AppoinmentLogin/LoginWithOtp`,
          { email }
        );

        // Store login details in localStorage
        localStorage.setItem('Token', response.data.Token);
        localStorage.setItem('Email', response.data.Email);
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('PatientCode', response.data.PatientCode);
        localStorage.setItem('Name', response.data.Name);
        localStorage.setItem('Contact', response.data.Contact);

        // Clear any previous error and navigate to the home page
        setErrorMessage("");
        alert("Login successful");
        navigate('/home');
      } catch (error) {
        // Check for server-side error and set the error message
        if (error.response && error.response.data && error.response.data.error) {
          console.error("Error response:", error.response);
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
        }
      }
    } else {
      setErrorMessage("Invalid OTP. Please check your email.");
    }
  };

  const handleCancel = () => {
    setEmail("");
    setOtp("");
    setIsOtpSent(false);
    setErrorMessage(""); // Clear any previous error message
  };

  return (
    <div className="login-page-container">
      <div className="left-login-page-container">
        <div className="welcome-patient-message">
          {/* <h1>Welcome Back!</h1> */}
        </div>
      </div>
      <div className="right-login-page-container">
        <div className="form-content">
          <h1 style={{ textAlign: "center" }}>Welcome Back!</h1>
          <p className="intro-text">Welcome back! Please log in to your account</p>

          <div className="group1">
            <label>Email</label>
            <input
              type="text"
              placeholder="Please enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button onClick={sendOtp}>Send OTP</button>

          {isOtpSent && (
            <>
              <label style={{ marginTop: "10px" }}>OTP</label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <div className="button-container">
                <button onClick={handleLogin}>Login</button>
                <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
              </div>
            </>
          )}

          {errorMessage && (
            <p style={{ color: 'red' }}>
              {errorMessage}
            </p>
          )}

          <p>Don't have an account?<Link to="/addusers"> Create One</Link></p> 
          
        </div>
      </div>
    </div>
  );
}
