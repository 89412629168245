import '../styles/login.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  // Load saved credentials on component mount
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Login/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        setErrorMessage(errorMessage.includes("Invalid username")
          ? "Invalid username. Please check and try again."
          : errorMessage.includes("Invalid password")
            ? "Invalid password. Please check and try again."
            : `Login failed: ${errorMessage}`);
        setIsLoading(false);
        return;
      }

      const data = await response.json();

      // Save user token and details
      localStorage.setItem('Token', data.Token);
      localStorage.setItem('Role', data.Role);
      localStorage.setItem('Name', data.Name);
      localStorage.setItem('id', data.id);

      // Handle "Remember Me" functionality
      if (rememberMe) {
        localStorage.setItem("rememberedUsername", form.username);// get the username and set it to rememberedUsername state
        localStorage.setItem("rememberedPassword", form.password);
      } else {
        localStorage.removeItem("rememberedUsername");
        localStorage.removeItem("rememberedPassword");
      }

      navigate("/dashboard/");
    } catch (error) {
      console.error('Error signing in:', error);
      setErrorMessage('Login failed: Unable to connect to the server.');
      setIsLoading(false);
    }
  };

  //saving the username and password as savedUsername and savedPassword  in localstorage
  useEffect(() => {
    const savedUsername = localStorage.getItem("rememberedUsername");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedUsername && savedPassword) {
      setForm({ username: savedUsername, password: savedPassword });
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="main-page-container">
      <div className="left-side-container">
        <div className="welcome-message"></div>
      </div>
      <div className="right-side-container">
        <div className="login-form-container">
          <form onSubmit={handleSubmit} className="main-form">
            <h1 style={{ textAlign: "center" }}>Welcome Back!</h1>
            <p className="intro-text">Please log in to your account</p>
            <div className="group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={form.username}
                onChange={handleChange}
                placeholder="Enter your username"
                required
              />
            </div>
            <div className="group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                placeholder="Enter your password"
                required
              />
            </div>

            <div className="options">
              <div className="remember-me-container">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                <label htmlFor="rememberMe">Remember Me</label>
              </div>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div className="form-buttons">
              <input
                type="submit"
                value={isLoading ? "Loading..." : "Login"}
                className="btn-submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
