// import React, { useState } from 'react';
// import '../styles/userRegistration.css';
// import axios from 'axios';
// import emailjs from 'emailjs-com';
// import { useNavigate } from 'react-router-dom';

// export default function UserRegistration() {
//   const [formData, setFormData] = useState({
//     MPD_EMAIL: '',
//     MPD_NIC_NO: '',
//     MPD_MOBILE_NO: '',
//     MPD_ADDRESS: '',
//     MPD_PASSWORD: '',
//     MPD_PATIENT_NAME:'',
//     MPD_BIRTHDAY:'',
   
//   });

//   const navigate = useNavigate("");
//   const [otp, setOtp] = useState('');
//   const [enteredOtp, setEnteredOtp] = useState('');
//   const [otpSent, setOtpSent] = useState(false);
//   const [otpVerified, setOtpVerified] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const sendOtp = async () => {
//     const generatedOtp = Math.floor(100000 + Math.random() * 900000).toString();
//     setOtp(generatedOtp);
//     console.log("Generated OTP:", generatedOtp);

//     try {
//       await emailjs.send(
//         'service_naq3m94', 
//         'template_5lc51z4',
//         {
//           to_name: 'User',
//           otp: generatedOtp,
//           email: formData.MPD_EMAIL,  // This sends the OTP to the user's email
//         },
//         '5Z0ZQCQbi6vWIGWpC'
//       );
//       setOtpSent(true);
//       setErrorMessage('');
//       setSuccessMessage('OTP has been sent to your email. Please check.');
//     } catch (error) {
//       setErrorMessage('Failed to send OTP. Please try again.');
//       setSuccessMessage('');
//       console.error("EmailJS Error:", error);
//     }
//   };

//   const verifyOtp = () => {
//     if (enteredOtp === otp) {
//       setOtpVerified(true);
//       setErrorMessage('');
//       setSuccessMessage('OTP verified successfully.');
//     } else {
//       setErrorMessage('Invalid OTP. Please try again.');
//       setSuccessMessage('');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     if (!otpVerified) {
//       setErrorMessage('Please verify the OTP before registering.');
//       return;
//     }
  
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/Patient/patient-registration`, formData);
//       setSuccessMessage('Patient registered successfully!');
//       setErrorMessage('');
//       alert("User registered successfully");
//       navigate("/");
//     } catch (error) {
//       console.error(error);
//       // Check if the server returned an error response
//       if (error.response && error.response.data && error.response.data.error) {
//         // Display the error message from the server
//         setErrorMessage(error.response.data.error);
//       } else {
//         setErrorMessage('Failed to register user. Please try again.');
//       }
//     }
//   };
  
  
//   const handleCancel = () => {
//     setFormData({
//       MPD_EMAIL: '',
//       MPD_NIC_NO: '',
//       MPD_MOBILE_NO: '',
//       MPD_ADDRESS: '',
//       MPD_PASSWORD: '',
//       MPD_PATIENT_NAME: '',
//       MPD_BIRTHDAY:'',
//     });
//     setErrorMessage('');
//     setSuccessMessage('');
//     setOtpSent(false);
//     setOtpVerified(false);
//     setEnteredOtp('');
//   };

//   return (
//     <div className="user-registration-container">
//       <div className="user-registration">
//         <form onSubmit={handleSubmit} className="registration-form">
//         {errorMessage && <div className="error-message">{errorMessage}</div>}
//           <h1>Register Patients</h1>

//           <div className="form-group">
//             <label>Email</label>
//             <input
//               type="email"
//               name="MPD_EMAIL"
//               placeholder="Enter your email"
//               value={formData.MPD_EMAIL}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           {!otpSent && (
//             <button type="button" onClick={sendOtp} className="btn-primary">
//               Send OTP
//             </button>
//           )}

//           {otpSent && !otpVerified && (
//             <div className="otp-verification">
//               <label>Enter OTP</label>
//               <input
//                 type="text"
//                 placeholder="Enter the OTP"
//                 value={enteredOtp}
//                 onChange={(e) => setEnteredOtp(e.target.value)}
//                 required
//               />
//               <button type="button" onClick={verifyOtp} className="btn-secondary">
//                 Verify OTP
//               </button>
//             </div>
//           )}

//           {otpVerified && (
//             <>
//               <div className="form-group">
//                 <label>NIC</label>
//                 <input
//                   type="text"
//                   name="MPD_NIC_NO"
//                   placeholder="Enter your NIC"
//                   value={formData.MPD_NIC_NO}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label>Your name</label>
//                 <input
//                   type="text"
//                   name="MPD_PATIENT_NAME"
//                   value={formData.MPD_PATIENT_NAME}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label>Contact</label>
//                 <input
//                   type="text"
//                   name="MPD_MOBILE_NO"
//                   placeholder="Enter your contact"
//                   value={formData.MPD_MOBILE_NO}
//                   onChange={handleChange}
//                   required
//                   maxLength="13"
//                 />
//               </div>
//               <div className='form-group'>
//                 <label>Birthdate</label>
//                  <input
//                  type="date"
//                  name="MPD_BIRTHDAY"
//                  placeholder="Enter your birthdate"
//                  value={formData.MPD_BIRTHDAY}

//                  />
//               </div>

//               <div className="form-group">
//                 <label>Address</label>
//                 <input
//                   type="text"
//                   name="MPD_ADDRESS"
//                   placeholder="Enter your address"
//                   value={formData.MPD_ADDRESS}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label>Password</label>
//                 <input
//                   type="password"
//                   name="MPD_PASSWORD"
//                   placeholder="Enter your password"
//                   value={formData.MPD_PASSWORD}
//                   onChange={handleChange}
//                   required
//                   minLength="6"
//                 />
//               </div>


//               <button type="submit" className="btn-primary">
//                 Register
//               </button>
//             </>
//           )}

//           {/* Display error and success messages */}
          
          
//           {successMessage && <div className="success-message">{successMessage}</div>}
//         </form>
//       </div>
//     </div>
//   );
// }
import React, { useState } from 'react';
import '../styles/userRegistration.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Userregistration() {
  const [formData, setFormData] = useState({
    MPD_EMAIL: '',
    MPD_NIC_NO: '',
    MPD_MOBILE_NO: '',
    MPD_ADDRESS: '',
    MPD_PASSWORD: '',
    MPD_PATIENT_NAME: '',
    MPD_BIRTHDAY: '',
  });

  const navigate = useNavigate("");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/Patient/patient-registration`, formData);
      setSuccessMessage('Patient registered successfully!');
      setErrorMessage('');
      alert("User registered successfully");
      navigate("/patient-login");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to register user. Please try again.');
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      MPD_EMAIL: '',
      MPD_NIC_NO: '',
      MPD_MOBILE_NO: '',
      MPD_ADDRESS: '',
      MPD_PASSWORD: '',
      MPD_PATIENT_NAME: '',
      MPD_BIRTHDAY: '',
    });
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <div className="user-registration-container">
      <div className="user-registration">
        <form onSubmit={handleSubmit} className="registration-form">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <h1>Register Patients</h1>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="MPD_EMAIL"
              placeholder="Enter your email"
              value={formData.MPD_EMAIL}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>NIC</label>
            <input
              type="text"
              name="MPD_NIC_NO"
              placeholder="Enter your NIC"
              value={formData.MPD_NIC_NO}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Your name</label>
            <input
              type="text"
              name="MPD_PATIENT_NAME"
              value={formData.MPD_PATIENT_NAME}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Contact</label>
            <input
              type="text"
              name="MPD_MOBILE_NO"
              placeholder="Enter your contact"
              value={formData.MPD_MOBILE_NO}
              onChange={handleChange}
              required
              maxLength="13"
            />
          </div>
          
          <div className='form-group'>
            <label>Birthdate</label>
            <input
              type="date"
              name="MPD_BIRTHDAY"
              placeholder="Enter your birthdate"
              value={formData.MPD_BIRTHDAY}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="MPD_ADDRESS"
              placeholder="Enter your address"
              value={formData.MPD_ADDRESS}
              onChange={handleChange}
              required
            />
          </div>

          {/* <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="MPD_PASSWORD"
              placeholder="Enter your password"
              value={formData.MPD_PASSWORD}
              onChange={handleChange}
              required
              minLength="6"
            />
          </div> */}

          <button type="submit" className="btn-primary">
            Register
          </button>

          {successMessage && <div className="success-message">{successMessage}</div>}
        </form>
      </div>
    </div>
  );
}
