import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/doctorprofile.css";

export default function Doctorprofile() {
  const [userDetails, setUserDetails] = useState({
    MUD_USER_NAME: "",
    MUD_USER_TYPE: "",
    MUD_SPECIALIZATION: "",
    MUD_STATUS: "",
    MUD_PHOTO: null,
    MUD_CONTACT:"",
    MUD_EMAIL:""
  });
  const [imagePreview, setImagePreview] = useState("default-profile-icon.png");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const id = localStorage.getItem("id");

  useEffect(() => {
    if (!id) {
      console.error("User ID is missing");
      return;
    }

    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/User/${id}`);
        const data = response.data;
        
        // Update MUD_USER_TYPE to "Doctor" if the response returns "doc"
        if (data.MUD_USER_TYPE === "Doc") {
          data.MUD_USER_TYPE = "Doctor";
        }



        setUserDetails(data);

        if (data.MUD_PHOTO) {
          setImagePreview(`data:image/png;base64,${data.MUD_PHOTO}`);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const updateUserProfile = async () => {
    const formData = new FormData();
    formData.append("MUD_USER_ID", id); // Ensure the ID is included
    formData.append("MUD_USER_NAME", userDetails.MUD_USER_NAME);
    formData.append("MUD_USER_TYPE", userDetails.MUD_USER_TYPE === "Doctor" ? "doc" : userDetails.MUD_USER_TYPE);
    formData.append("MUD_SPECIALIZATION", userDetails.MUD_SPECIALIZATION);
    formData.append("MUD_STATUS", userDetails.MUD_STATUS);
    formData.append("MUD_PASSWORD", userDetails.MUD_PASSWORD);
    formData.append("MUD_CONTACT",userDetails.MUD_CONTACT);
    formData.append("MUD_EMAIL",userDetails.MUD_EMAIL);
  
    if (selectedFile) {
      formData.append("MUD_PHOTO", selectedFile);
    }
  
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/User/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Profile updated successfully:", response.data);
      alert("Profile updated successfully!");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Bad Request:", error.response.data);
      } else {
        console.error("Error updating profile:", error);
      }
      alert("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="doctor-profile-container">
      <h1>Your Profile</h1>

      <label htmlFor="profileImageUpload" className="profile-image-label">
        <img src={imagePreview} alt="Profile" className="profile-icon" />
        <span className="edit-icon">✏️</span>
      </label>

      <input
        id="profileImageUpload"
        type="file"
        name="profileImage"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />

      <div className="user-form">
        <div className="form-grid">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              name="MUD_USER_NAME"
              value={userDetails.MUD_USER_NAME}
              onChange={handleInputChange}
              placeholder="Enter username"
             
            />
          </div>

          <div className="form-group">
            <label>User Category</label>
            <input
              type="text"
              name="MUD_USER_TYPE"
              value={userDetails.MUD_USER_TYPE}
              onChange={handleInputChange}
              placeholder="Enter user category"
              readOnly
            />
          </div>

          <div className="form-group">
            <label>Specialization</label>
            <input
              type="text"
              name="MUD_SPECIALIZATION"
              value={userDetails.MUD_SPECIALIZATION}
              onChange={handleInputChange}
              placeholder="Enter specialization"
            />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="text"
              name="MUD_PASSWORD"
              value={userDetails.MUD_PASSWORD}
              onChange={handleInputChange}
              placeholder="Enter password"
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input

            type="text"
            name="MUD_EMAIL"
            value={userDetails.MUD_EMAIL}
            onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
  <label>Contact</label>
  <input 
    type="text"
    name="MUD_CONTACT"
    value={userDetails.MUD_CONTACT}
    onChange={handleInputChange}  // Use handleInputChange here
  />
</div>

        </div>

        <button onClick={updateUserProfile} className="update-profile-button" disabled={loading}>
          {loading ? "Updating..." : "Update Profile"}
        </button>
      </div>
    </div>
  );
}
